'use strict';

// Constructor
var EgModal = function() {
    var modal = $('.eg-modal');
    var modalTriggers = $('[data-modal]');

    if (modal) {
        modalTriggers.on('click', function() {
            var actionType = $(this).data('modal');

            if (actionType === 'open') {
                openModal();
            } else {
                closeModal();
            }
        });

        function openModal() {
            modal.addClass('-open');
            $('body').addClass('-hideOverflow');
        }

        function closeModal() {
            modal.removeClass('-open');
            $('body').removeClass('-hideOverflow');
        }
    }

    var votarButton = $('[href="#votar"]');
    var terminosButton = $('[href="#terminos"]');
    var votarBlock = $('#votar');
    var terminosBlock = $('#terminos');
    var autorizacionButton = $('[href="#autorizacion"]');
    var autorizacionBlock = $('#autorizacion');

    votarButton.on('click', function() {
        autorizacionBlock.addClass('-hidden');
        terminosBlock.addClass('-hidden');
        votarBlock.removeClass('-hidden');
    });

    terminosButton.on('click', function() {
        autorizacionBlock.addClass('-hidden');
        votarBlock.addClass('-hidden');
        terminosBlock.removeClass('-hidden');
    });

    autorizacionButton.on('click', function() {
        votarBlock.addClass('-hidden');
        terminosBlock.addClass('-hidden');
        autorizacionBlock.removeClass('-hidden');
    });
};

module.exports = EgModal;
